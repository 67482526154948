#team{
    #team-tile{
        padding-bottom: 4rem;
    }
    #guests-tile{
        padding: 2rem 0;
    }
    .hosts-wrapper{
        display: flex;
        width: 100%;
    }
    .hosts{
        display: inline-flex;
        width: 100%;
        @include mq('tablet'){
            flex-direction: column;
        }
        .host{
            width: 50%;
            padding-left: .75rem;
            padding-right: .75rem;
            @include mq('tablet'){
                width: 100%;
                display: flex;
                padding: 0;
                margin-bottom: 3rem;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
            &:first-of-type{
                padding-left: 0;
            }
            &:last-of-type{
                padding-right: 0;
            }
            .block-content{
                background-color: darken(white, 10%);
                background-size: cover;
                width: 100%;
                background-position: center;
                min-height: 20rem;
                align-items: center;
                justify-content: center;
                display: flex;
                position: relative;
                h2{
                    position: absolute;
                    bottom: -1rem;
                    left: 1rem;
                    background: $primary;
                    color: white;
                    // border: 1px solid darken(white, 20%);
                    padding: .5rem;
                    padding-right: 1.5rem;
                    padding-top: .75rem;
                    padding-left: .75rem;
                    @extend .card-shadow;
                }
            }
            &.mark-young .block-content{
                background-image: url('/img/hosts/mark-young.jpg');
            }
            &.dr-gladden .block-content{
                background-image: url('/img/hosts/dr-gladden.jpg');
            }
            &.robb-taylor .block-content{
                background-image: url('/img/hosts/robb-taylor.jpg');
            }
        }
    }
}
