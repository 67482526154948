#team-show{
    .bio-wrapper{
        margin-bottom: 2rem;
        &:last-of-type{
            margin-bottom: 0;
        }
        p{
            font-size: 1.15rem;
            line-height: 1.85rem;
            margin-bottom: 1.5rem;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        h2{
            margin-bottom: .5rem;
        }
    }
    .images-wrapper{
        // padding: 1.5rem;
        margin-bottom: 2rem;
        .image-wrapper{
            padding-left: .75rem;
            padding-right: .75rem;
            width: 33.3333%;
            float: left;
            &:first-of-type{
                padding-left: 0;
            }
            &:last-of-type{
                padding-right: 0;
            }
        }
        .image{
            height: 17rem;
            background-color: darken(white, 20%);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            margin-bottom: 1.5rem;
        }
        .container{
            flex-direction: row;
        }
        @include mq('tablet'){
            .image-wrapper{
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
            .image{
                height: 20rem;
            }
        }
    }
    .image-banner{
        height: 25rem;
        background-color: darken(white, 20%);
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .host-bio{
        background: white;
        padding-left: 1rem;
        padding-right: 1rem;
        .social-icons{
            .fa {
                color: $primary;
            }
            margin-bottom: 2rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid darken(white, 20%);
            flex-direction: column;
            .social-title{
                display: block;
                margin-bottom: 1rem;
                font-size: 1rem;
            }
            ul{
                display: flex;
            }
        }
    }
}
