#contact{
    .error-list {
        color: #ff0000;
        padding: 10px;
    }
    .form-input {
        padding: 10px;
        @include input-placeholder;
    }
    .msg{
        padding: .5em;
        margin: 1em 0;

        p {
            margin: 0;
            text-align: center;
        }

        &.success {
            border: 2px solid #29a913;
            background-color: transparentize(#29a913, 0.7);
            color: #636363;
        }
        &.error {
            border: 2px solid #ff0000;
            background-color: transparentize(#ff0000, 0.7);
            color: #636363;
        }
    }
    .submit {
        background: blue;
        color: white;
    }
    .two-col {
        display:flex;
        @media screen and (max-width:60em){
            flex-direction:column;
        }
    }
    .contact-info {
        margin-left:2em;
        min-width:30%;
        @media screen and (max-width:60em){
            margin:2em 0 0;
        }
    }
    address {
        line-height:1.5;
        margin-bottom:1em;
    }
}

