// add mobile nav btn
// min-height: 1px;
a{
  text-decoration: none;
  color: $primary;
  @include mq('tablet', 'min'){
    &:hover{
      color: darken($primary, 10%) !important;
    }
  }
  @include mq('tablet'){
    &:active{
      color: darken($primary, 10%) !important;
    }
  }
  @include ease();
}
.page-content{
  min-height: 100vh;
}
.breadcrumbs-wrapper{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: $dark-4;
  font-size: .9rem;
  a{
    color: $dark-4;
  }
  &.inverted{
    a{
      color: rgba(255,255,255,.8);
    }
  }
  .breadcrumbs{
    .breadcrumb{
      display: inline-flex;
      font-style: italic;
      align-items: center;
      line-height: 1.5rem;
      &:after{
        @extend .fa-custom;
        content: '\f0da';
        padding-left: .75rem;
        font-size: .6rem;
      }
      &:last-of-type{
        &:after{
          display: none;
          content: '';
        }
      }
    }
  }
}
.errors-banner, .success-banner{
  text-align: left;
  padding: 1em;
  ul li{
    display: block;
    margin-bottom: 1em;
    &:last-child{
      margin-bottom: 0;
    }
  }
  i{
    margin-right: .25em;
  }
}
.errors-banner{
  background: $error-background;
  color: $error-color;
}
.success-banner{
  background: $success-background;
  color: $success-color;
}
.nowrap{
  display: inline-block;
}
.inline-link{
  color: $primary;
  text-decoration: underline;
  font-weight: normal;
}
.learn-more{
  font-size: 50%;
  text-decoration: underline;
  color: lighten(black, 60%);
  @extend .nowrap;
  @include mq("tablet-wide"){
    font-size: 75%;
  }
  @include mq("phablet"){
    font-size: 80%;
  }
}
body{
  font-family: $body;
  letter-spacing: 0.02em;
  overflow-x: hidden;
}
section{
  // padding: 3em 0em;
}
strong{
  font-weight: bold;
}
.social-icons{
  @include last-child('right', .75em);
  span{
    display: none;
  }
}

footer{
  background: $footer-background;
  font-size: 70%;
  padding: 2em 0;
  border-top: 1px solid darken(white, 15%);
  color: lighten(black, 50%);
  // extended footer
  box-shadow: 0px 500px 0px 500px $footer-background;
  [class*=span]{
    &:last-child{
      text-align: right;
    }
  }
  nav{
    @include last-child('bottom', .75em);
    ul li a{
      color: lighten(black, 40%);
      &.active{
        color: lighten(black, 40%);
      }
    }
  }
}
*{
  box-sizing: border-box;
}
.field{
  margin-bottom: 1em;
  display: block;
  padding-left: .5em;
  padding-right: .5em;
  &:first-of-type{
    padding-left: 0;
  }
  &:last-of-type{
    padding-right: 0;
  }
}
em{
  font-style: italic;
}
nav{
  display: inline-block;
  ul{
    li{
      margin-right: .25em;
      text-transform: uppercase;
      display: inline-block;
      &:last-child{
        margin-right: 0;
      }
      a{
        &:hover{
        }
        &.active{
          color: $primary;
        }
      }
    }
  }
}
label{
  display: block;
  margin-bottom: .5em;
  &.required{
    &:after{
      content: "*";
      color: red;
    }
  }
}
.container{
  @include mq('container'){
    padding: 0 .75em;
  }
}
input:not([type=radio]), textarea{
  width: 100%;
  padding: .35em;
  background: white;
  border: 1px solid darken(white, 15%);
  color: lighten(black, 40%);
  &.phone{
    letter-spacing: 0.05em;
  }
  &[type=radio]{
    width: auto;
  }
}
.field{
  margin-bottom: 1em;
  display: block;
  padding-left: .5em;
  padding-right: .5em;
  &:first-of-type{
    padding-left: 0;
  }
  &:last-of-type{
    padding-right: 0;
  }
}
aside{
  // h4{
  //   font-size: 1.25em;
  // }
  p{
    font-size: .9em;
  }
  margin-left: 2em;
  // flex: 0 0 12em;
  padding-left: 1em;
  border-left: 1px solid darken(white, 10%);
}
label{
  text-transform: uppercase;
  font-size: .8em;
  display: block;
  margin-bottom: .5em;
  &.required{
    &:after{
      content: "*";
      color: red;
    }
  }
}
span.baby-text{
  font-size: 70%;
}
h1, h2, h3, h4, h5, h6{
  font-family: $headline;
  font-weight: bold;
  &:last-child{
    margin-bottom: 0;
  }
}
h1{
  font-size: 2em;
  margin-bottom: 1em;
}
h2{
  font-size: 1.75em;
  margin-bottom: .75em;
}
h3{
  font-size: 1.5em;
  margin-bottom: .5em;
}
h4{
  font-size: 1.25em;
  margin-bottom: .5em;
}
h5{
  font-size: 1.15em;
  margin-bottom: .5em;
}
h6{
  font-size: 1.10em;
  margin-bottom: .25em;
}
.mobile-nav{
  width: 200px;
  height: 100%;
  position: fixed;
  top: 1em;
  right: -200px;
  z-index: z('mobile-nav');
  nav{
    // text-align: right;
    position: fixed;
    width: 100%;
    font-size: 1em;
    padding: 1em;
    ul{
      li{
        margin-bottom: 1.25em;
      }
    }
  }
}
.mobile-nav-btn{
  float: right;
  display: none;
  font-size: 2em;
  i{
    &.hide{
      display: none;
    }
  }
  @include mq('tablet-small'){
    display: inline-flex;
  }
}
textarea:focus, input:focus{
    outline: none;
    border-color: $primary !important;
}
p{
  font-size: 1.05em;
  line-height: 1.5em;
  margin-bottom: .75em;
  color: lighten(black, 20%);
  font-family: $body;
  font-weight: 500;
  &:last-child{
    margin-bottom: 0;
  }
}
textarea{
  max-width: 100%;
  min-width: 100%;
  min-height: 10em;
}
table{
  width: 100%;
  color: black;
  .credits-col{
    text-align: right;
  }
  thead{
    border-bottom: 2px solid black;
    th{
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
      padding: .5em;
    }

  }
  tbody{

  }
  tr{
    border-bottom: 1px dashed darken(white, 30%);
    &:nth-child(even){
      background: darken(white, 3%);
    }
    &:last-child{
      border-bottom: 0;
    }
  }
  td{
    line-height: 1.4em;
    padding: .5em;
  }
}
.page-content, .mobile-nav, header {
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);

  -moz-transform: translateZ(0) translateX(0);
  -ms-transform: translateZ(0) translateX(0);
  -o-transform: translateZ(0) translateX(0);
  -webkit-transform: translateZ(0) translateX(0);
  transform: translateZ(0) translateX(0);

  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -moz-transition: 300ms ease all;
  -ms-transition: 300ms ease all;
  -o-transition: 300ms ease all;
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all;

  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.show-nav .page-content, .show-nav .mobile-nav, .show-nav header {
  -moz-transform: translateX(-200px);
  -ms-transform: translateX(-200px);
  -o-transform: translateX(-200px);
  -webkit-transform: translateX(-200px);
  transform: translateX(-200px);

  -moz-transform: translateZ(0) translateX(-200px);
  -ms-transform: translateZ(0) translateX(-200px);
  -o-transform: translateZ(0) translateX(-200px);
  -webkit-transform: translateZ(0) translateX(-200px);
  transform: translateZ(0) translateX(-200px);

  -moz-transform: translate3d(-200px, 0, 0);
  -ms-transform: translate3d(-200px, 0, 0);
  -o-transform: translate3d(-200px, 0, 0);
  -webkit-transform: translate3d(-200px, 0, 0);
  transform: translate3d(-200px, 0, 0);
}
