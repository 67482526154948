/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,800");
@import url("https://fonts.googleapis.com/css?family=Eczar:400,500,600,700,800");
@import url(/fonts/font-awesome-4.4.0/css/font-awesome.min.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="text"],
textarea,
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus,
input[type="text"]:focus,
textarea:focus,
button:focus {
  outline: 0;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner:focus,
button::-moz-focus-inner:focus {
  outline: 0;
}

@viewport {
  zoom: 1.0;
  width: extend-to-zoom;
}

@-ms-viewport {
  width: extend-to-zoom;
  zoom: 1.0;
}

.container {
  /* * For IE 6/7 only * Include this rule to trigger hasLayout and contain floats. */
  *zoom: 1;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  box-sizing: border-box;
}

.container:after {
  clear: both;
}

.container:before,
.container:after {
  content: " ";
  display: table;
}

.row {
  /* * For IE 6/7 only * Include this rule to trigger hasLayout and contain floats. */
  *zoom: 1;
}

.row:after {
  clear: both;
}

.row:before,
.row:after {
  content: " ";
  display: table;
}

[class^="span"] {
  float: left;
  min-height: 1px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
}

[class^="span"]:first-child {
  margin-left: 0;
}

.span1 {
  width: 8.333333333333332%;
}

.span2 {
  width: 16.666666666666664%;
}

.span3 {
  width: 25%;
}

.span4 {
  width: 33.33333333333333%;
}

.span5 {
  width: 41.66666666666667%;
}

.span6 {
  width: 50%;
}

.span7 {
  width: 58.333333333333336%;
}

.span8 {
  width: 66.66666666666666%;
}

.span9 {
  width: 75%;
}

.span10 {
  width: 83.33333333333334%;
}

.span11 {
  width: 91.66666666666666%;
}

.span12 {
  width: 100%;
}

[class^="m-span"] {
  padding: 10px 0;
  margin-left: 3.125%;
  float: left;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
}

[class^="m-span"]:first-child {
  margin-left: 0;
}

.m-span1 {
  width: 5.46875%;
}

.m-span2 {
  width: 14.0625%;
}

.m-span3 {
  width: 22.65625%;
}

.m-span4 {
  width: 31.25%;
}

.m-span5 {
  width: 39.84375%;
}

.m-span6 {
  width: 48.4375%;
}

.m-span7 {
  width: 57.03125%;
}

.m-span8 {
  width: 65.625%;
}

.m-span9 {
  width: 74.21875%;
}

.m-span10 {
  width: 82.8125%;
}

.m-span11 {
  width: 91.40625%;
}

.m-span12 {
  width: 100%;
}

.nowrap, .learn-more {
  display: inline-block;
}

.card-shadow, aside.recent-episodes .thumbnail, .btn, .content-wrapper, header, .page-message, #episode .episode-guest-wrapper .guest-image-wrapper .guest-image, #episode .episode-title, #team .hosts .host .block-content h2, #article .post-wrapper .image-wrapper {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}

.background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.text-shadow, #home #home-blocks-wrapper .box-wrapper.episode-preview .box .box-content, #home #home-blocks-wrapper .box, #episodes .episode-box .episode-title, #episodes .episode-box .episode-number {
  text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.2);
}

.text-shadow-light {
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
}

.text-shadow-hard, .btn {
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);
}

.fa-custom, .breadcrumbs-wrapper .breadcrumbs .breadcrumb:after {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: left;
  display: inline-block;
}

a {
  text-decoration: none;
  color: #102aa3;
  transition: all 0.25s ease;
}

@media only screen and (min-width: 768px) {
  a:hover {
    color: #0b1e75 !important;
  }
}

@media only screen and (max-width: 767px) {
  a:active {
    color: #0b1e75 !important;
  }
}

a:hover {
  transition: all 0.25s ease;
}

.page-content {
  min-height: 100vh;
}

.breadcrumbs-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #666666;
  font-size: .9rem;
}

.breadcrumbs-wrapper a {
  color: #666666;
}

.breadcrumbs-wrapper.inverted a {
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumbs-wrapper .breadcrumbs .breadcrumb {
  display: inline-flex;
  font-style: italic;
  align-items: center;
  line-height: 1.5rem;
}

.breadcrumbs-wrapper .breadcrumbs .breadcrumb:after {
  content: '\f0da';
  padding-left: .75rem;
  font-size: .6rem;
}

.breadcrumbs-wrapper .breadcrumbs .breadcrumb:last-of-type:after {
  display: none;
  content: '';
}

.errors-banner, .success-banner {
  text-align: left;
  padding: 1em;
}

.errors-banner ul li, .success-banner ul li {
  display: block;
  margin-bottom: 1em;
}

.errors-banner ul li:last-child, .success-banner ul li:last-child {
  margin-bottom: 0;
}

.errors-banner i, .success-banner i {
  margin-right: .25em;
}

.errors-banner {
  background: #FFBABA;
  color: #D8000C;
}

.success-banner {
  background: #DFF2BF;
  color: #4F8A10;
}

.nowrap, .learn-more {
  display: inline-block;
}

.inline-link {
  color: #102aa3;
  text-decoration: underline;
  font-weight: normal;
}

.learn-more {
  font-size: 50%;
  text-decoration: underline;
  color: #999999;
}

@media only screen and (max-width: 1023px) {
  .learn-more {
    font-size: 75%;
  }
}

@media only screen and (max-width: 559px) {
  .learn-more {
    font-size: 80%;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.02em;
  overflow-x: hidden;
}

strong {
  font-weight: bold;
}

.social-icons ul li, .social-icons > div {
  margin-right: 0.75em;
  margin-bottom: 0;
  display: inline-block;
}

.social-icons ul li:last-child, .social-icons > div:last-child {
  margin-right: 0;
  border-right: 0;
}

.social-icons ul li:last-child:after, .social-icons > div:last-child:after {
  display: none;
}

.social-icons span {
  display: none;
}

footer {
  background: #e6e6e6;
  font-size: 70%;
  padding: 2em 0;
  border-top: 1px solid #d9d9d9;
  color: gray;
  box-shadow: 0px 500px 0px 500px #e6e6e6;
}

footer [class*=span]:last-child {
  text-align: right;
}

footer nav ul li, footer nav > div {
  display: block;
  margin-right: 0;
  margin-bottom: 0.75em;
}

footer nav ul li:last-child, footer nav > div:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

footer nav ul li:after, footer nav > div:after {
  display: none;
  padding: 0;
}

footer nav ul li a {
  color: #666666;
}

footer nav ul li a.active {
  color: #666666;
}

* {
  box-sizing: border-box;
}

.field {
  margin-bottom: 1em;
  display: block;
  padding-left: .5em;
  padding-right: .5em;
}

.field:first-of-type {
  padding-left: 0;
}

.field:last-of-type {
  padding-right: 0;
}

em {
  font-style: italic;
}

nav {
  display: inline-block;
}

nav ul li {
  margin-right: .25em;
  text-transform: uppercase;
  display: inline-block;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a.active {
  color: #102aa3;
}

label {
  display: block;
  margin-bottom: .5em;
}

label.required:after {
  content: "*";
  color: red;
}

@media only screen and (max-width: 1099px) {
  .container {
    padding: 0 .75em;
  }
}

input:not([type=radio]), textarea {
  width: 100%;
  padding: .35em;
  background: white;
  border: 1px solid #d9d9d9;
  color: #666666;
}

input:not([type=radio]).phone, textarea.phone {
  letter-spacing: 0.05em;
}

input:not([type=radio])[type=radio], textarea[type=radio] {
  width: auto;
}

.field {
  margin-bottom: 1em;
  display: block;
  padding-left: .5em;
  padding-right: .5em;
}

.field:first-of-type {
  padding-left: 0;
}

.field:last-of-type {
  padding-right: 0;
}

aside {
  margin-left: 2em;
  padding-left: 1em;
  border-left: 1px solid #e6e6e6;
}

aside p {
  font-size: .9em;
}

label {
  text-transform: uppercase;
  font-size: .8em;
  display: block;
  margin-bottom: .5em;
}

label.required:after {
  content: "*";
  color: red;
}

span.baby-text {
  font-size: 70%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Eczar", serif;
  font-weight: bold;
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 2em;
  margin-bottom: 1em;
}

h2 {
  font-size: 1.75em;
  margin-bottom: .75em;
}

h3 {
  font-size: 1.5em;
  margin-bottom: .5em;
}

h4 {
  font-size: 1.25em;
  margin-bottom: .5em;
}

h5 {
  font-size: 1.15em;
  margin-bottom: .5em;
}

h6 {
  font-size: 1.10em;
  margin-bottom: .25em;
}

.mobile-nav {
  width: 200px;
  height: 100%;
  position: fixed;
  top: 1em;
  right: -200px;
  z-index: 3;
}

.mobile-nav nav {
  position: fixed;
  width: 100%;
  font-size: 1em;
  padding: 1em;
}

.mobile-nav nav ul li {
  margin-bottom: 1.25em;
}

.mobile-nav-btn {
  float: right;
  display: none;
  font-size: 2em;
}

.mobile-nav-btn i.hide {
  display: none;
}

@media only screen and (max-width: 639px) {
  .mobile-nav-btn {
    display: inline-flex;
  }
}

textarea:focus, input:focus {
  outline: none;
  border-color: #102aa3 !important;
}

p {
  font-size: 1.05em;
  line-height: 1.5em;
  margin-bottom: .75em;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}

p:last-child {
  margin-bottom: 0;
}

textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 10em;
}

table {
  width: 100%;
  color: black;
}

table .credits-col {
  text-align: right;
}

table thead {
  border-bottom: 2px solid black;
}

table thead th {
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  padding: .5em;
}

table tr {
  border-bottom: 1px dashed #b3b3b3;
}

table tr:nth-child(even) {
  background: #f7f7f7;
}

table tr:last-child {
  border-bottom: 0;
}

table td {
  line-height: 1.4em;
  padding: .5em;
}

.page-content, .mobile-nav, header {
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -moz-transform: translateZ(0) translateX(0);
  -ms-transform: translateZ(0) translateX(0);
  -o-transform: translateZ(0) translateX(0);
  -webkit-transform: translateZ(0) translateX(0);
  transform: translateZ(0) translateX(0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -moz-transition: 300ms ease all;
  -ms-transition: 300ms ease all;
  -o-transition: 300ms ease all;
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.show-nav .page-content, .show-nav .mobile-nav, .show-nav header {
  -moz-transform: translateX(-200px);
  -ms-transform: translateX(-200px);
  -o-transform: translateX(-200px);
  -webkit-transform: translateX(-200px);
  transform: translateX(-200px);
  -moz-transform: translateZ(0) translateX(-200px);
  -ms-transform: translateZ(0) translateX(-200px);
  -o-transform: translateZ(0) translateX(-200px);
  -webkit-transform: translateZ(0) translateX(-200px);
  transform: translateZ(0) translateX(-200px);
  -moz-transform: translate3d(-200px, 0, 0);
  -ms-transform: translate3d(-200px, 0, 0);
  -o-transform: translate3d(-200px, 0, 0);
  -webkit-transform: translate3d(-200px, 0, 0);
  transform: translate3d(-200px, 0, 0);
}

.main-content {
  padding: 2rem 0;
  background: linear-gradient(180deg, #f2f2f2 0, #f2f2f2 9em, white 0, white);
}

.main-content h1 {
  color: #102aa3;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
}

@media only screen and (max-width: 1023px) {
  .main-content .container {
    display: flex;
    flex-direction: column;
  }
  .main-content .container aside {
    display: flex;
    padding: 0 2rem;
    margin: 0;
    margin-top: 2rem;
    flex-direction: column;
  }
  .main-content .container .content-wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1023px) {
  .main-content {
    padding: 1.5rem 0;
  }
}

.speakpipe-wrapper {
  margin-top: 2rem;
}

h3 {
  font-family: "Open Sans", sans-serif;
}

footer {
  padding: 1rem 1.5rem;
  padding-bottom: 4rem;
}

@media only screen and (max-width: 639px) {
  footer {
    padding: 1rem 0.5rem;
  }
}

footer nav {
  display: flex;
  align-items: flex-start;
  flex-flow: wrap;
  margin: 1rem 0;
}

footer nav ul {
  min-width: 15%;
  margin-right: 1em;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 639px) {
  footer nav ul {
    flex: 1 1 30%;
  }
}

footer nav ul li.header {
  margin-bottom: 1em;
  font-weight: 600;
  color: #000;
}

footer nav ul:last-child {
  margin-right: 0;
}

footer .cards {
  margin-left: auto;
  max-width: 20em;
}

@media only screen and (max-width: 639px) {
  footer .cards {
    margin: 1rem auto;
  }
}

.player {
  border: 1px solid #4d4d4d;
  width: 100%;
  margin-bottom: 1rem;
}

.player audio {
  width: 100%;
}

.subscribe-banner {
  background: #666666;
  padding: 1rem;
  color: white;
  display: flex;
  align-items: center;
}

.subscribe-banner .container {
  display: flex;
  align-items: center;
}

.subscribe-banner ul {
  margin-left: 2rem;
  display: inline-flex;
  flex-wrap: wrap;
}

.subscribe-banner ul li {
  display: inline-flex;
  margin-right: 1rem;
}

.subscribe-banner ul li:last-of-type {
  margin-right: 0;
}

.subscribe-banner ul li img {
  max-height: 40px;
}

.subscribe-banner h4 {
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  display: inline-flex;
  font-size: 1rem;
  text-align: center;
  line-height: 1.15rem;
}

@media only screen and (max-width: 639px) {
  .subscribe-banner .container {
    flex-direction: column;
  }
  .subscribe-banner h4 {
    margin-bottom: 1rem;
  }
  .subscribe-banner ul {
    margin-left: 0;
  }
}

.subscribe-wrapper {
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: flex-end;
  text-transform: uppercase;
}

.subscribe-wrapper span, .subscribe-wrapper ul {
  display: inline-flex;
}

.subscribe-wrapper li {
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 1px solid #cccccc;
  display: inline-flex;
}

.subscribe-wrapper li:last-of-type {
  margin-right: 0;
  padding-right: 0;
  border: 0;
}

.subscribe-wrapper span {
  margin-right: 1rem;
}

.subscribe-wrapper a {
  text-decoration: underline;
}

@media only screen and (max-width: 559px) {
  .subscribe-wrapper {
    align-items: center;
    flex-direction: column;
  }
  .subscribe-wrapper span {
    margin-bottom: .5rem;
    margin-right: 0;
  }
}

.share-wrapper {
  display: block;
  margin-bottom: 3rem;
}

.share-wrapper h5 {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: italic;
  display: block;
  margin-bottom: 1rem;
}

.share-wrapper ul li a {
  padding: .35rem 1rem;
  color: white;
  font-size: .9rem;
}

@media only screen and (min-width: 768px) {
  .share-wrapper ul li a:hover {
    color: white !important;
  }
}

.share-wrapper ul li a i {
  margin-right: .35rem;
}

.share-wrapper ul li a.fb {
  background-color: #3b5998;
}

.share-wrapper ul li a.tw {
  background-color: #00aced;
}

.share-wrapper ul li a.gp {
  background-color: #df4a32;
}

.share-wrapper ul li a.pi {
  background-color: #cb2027;
}

.share-wrapper ul li, .share-wrapper > div {
  margin-right: 0.75rem;
  margin-bottom: 0;
  display: inline-block;
}

.share-wrapper ul li:last-child, .share-wrapper > div:last-child {
  margin-right: 0;
  border-right: 0;
}

.share-wrapper ul li:last-child:after, .share-wrapper > div:last-child:after {
  display: none;
}

aside {
  float: right;
  width: 20rem;
  border: 0;
}

aside h4 {
  font-family: "Open Sans", sans-serif;
  color: #102aa3;
  border-bottom: 3px solid #ffb602;
  text-transform: uppercase;
  display: inline-block;
  font-size: 1rem;
  padding: 0 3rem .25rem .5rem;
  margin-bottom: 2rem;
  flex-grow: 0;
}

aside.recent-episodes ul li {
  display: flex;
  margin-bottom: 1.5rem;
}

aside.recent-episodes ul li:last-of-type {
  margin-bottom: 0;
}

aside.recent-episodes ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

aside.recent-episodes .thumbnail {
  min-height: 5rem;
  max-height: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  display: inline-flex;
  background-color: #e6e6e6;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #4d4d4d;
}

aside.recent-episodes .secondary-btn {
  margin-top: 2rem;
}

aside.recent-episodes .info {
  padding: .5rem .5rem;
  padding-left: 1.25rem;
  display: inline-flex;
  flex-direction: column;
}

aside.recent-episodes .info .title {
  font-family: "Eczar", serif;
  color: black;
  font-size: 1.25rem;
}

aside.recent-episodes .info .episode-number {
  text-transform: uppercase;
  margin-bottom: .5rem;
  color: #102aa3;
  font-weight: 600;
  font-size: .75rem;
}

@media only screen and (max-width: 1023px) {
  aside.recent-episodes {
    width: 100%;
  }
  aside.recent-episodes .secondary-btn {
    width: auto;
  }
}

@media only screen and (max-width: 1023px) {
  aside {
    margin-left: 1rem;
  }
}

.mobile-nav {
  top: 0;
  background: #ededed;
  font-size: 1.15rem;
  border-left: 2px solid #102aa3;
}

.mobile-nav ul li, .mobile-nav > div {
  display: block;
  margin-right: 0;
  margin-bottom: 1.25rem;
}

.mobile-nav ul li:last-child, .mobile-nav > div:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.mobile-nav ul li:after, .mobile-nav > div:after {
  display: none;
  padding: 0;
}

.mobile-nav nav {
  font-weight: bold;
  position: relative;
}

.mobile-nav .social-icons {
  display: flex;
  padding: .5rem;
  margin-top: 1rem;
  align-items: center;
  flex-direction: column;
}

.mobile-nav .social-icons li {
  display: inline-flex;
}

.share-wrapper {
  margin-top: 2rem;
  border-top: 1px solid #b3b3b3;
  padding-top: 1rem;
}

.mobile-nav-btn {
  color: white;
  margin-left: 2rem;
}

@media only screen and (max-width: 639px) {
  .mobile-nav-btn {
    margin-left: 0;
  }
}

.post-content {
  padding-left: 4rem;
}

.post-content p {
  font-size: 1.15rem;
  line-height: 1.75rem;
  margin-bottom: 1.75rem;
}

@media only screen and (max-width: 1023px) {
  .post-content {
    margin-right: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .post-content {
    margin-right: 0;
  }
}

.secondary-btn {
  text-transform: uppercase;
  color: #102aa3;
  font-size: 1.05rem;
  font-weight: bold;
  display: inline-flex;
  padding-bottom: .25rem;
  border-bottom: 3px solid transparent;
}

.secondary-btn i, .secondary-btn svg {
  margin-right: 0;
  text-align: right !important;
  width: 1rem;
}

@media only screen and (min-width: 768px) {
  .secondary-btn:hover {
    border-bottom: 3px solid #102aa3;
  }
}

.read-more {
  font-weight: bold;
  color: white;
  font-size: 1.1rem;
  text-transform: uppercase;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .read-more:hover {
    color: #ffb602 !important;
  }
}

.post-footer-wrapper {
  margin-top: 4rem;
  font-style: italic;
  font-size: 1rem;
}

.copy {
  font-size: 1.15rem;
}

.copy a {
  text-decoration: underline;
}

.copy ul {
  margin-bottom: 2rem;
  margin-left: 1rem;
}

.copy ul li {
  list-style-position: inside;
  list-style-type: disc;
  padding-left: 1.5em;
  text-indent: -1.5em;
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
}

.copy ul li:last-of-type {
  margin-bottom: 0;
}

sup, sub {
  font-size: .75rem;
  line-height: 1rem;
}

sup {
  vertical-align: top;
}

sub {
  vertical-align: bottom;
}

.btn {
  display: inline-block;
  color: white;
  text-transform: uppercase;
  padding: .75rem 1.15rem;
  background: #e6e6e6;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
}

.btn i {
  margin-left: .25rem;
}

.btn.main {
  background: #ffb602;
  border-color: #ce9300;
}

.content-wrapper {
  width: auto;
  overflow: hidden;
  background: white;
  border: 1px solid #d9d9d9;
  padding: 2rem;
}

.page-content {
  padding-top: 4rem;
}

@media only screen and (max-width: 639px) {
  .page-content {
    padding-top: 3.5rem;
  }
}

.social-icons {
  display: inline-flex;
  font-size: 1.25rem;
}

.social-icons li {
  margin-right: 1rem;
  display: inline-flex;
}

.social-icons li:last-of-type {
  margin-right: 0;
}

.social-icons li a {
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

@media only screen and (min-width: 768px) {
  .social-icons li a:hover {
    color: white !important;
  }
}

.inline-link.facebook {
  color: #3b5998;
}

.inline-link.twitter {
  color: #00aced;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 4;
  border-bottom: 0;
}

header .main-header {
  height: 4rem;
  display: flex;
  padding: 0 1rem;
  width: 100%;
  padding-left: 0;
  flex-direction: row;
  align-items: center;
  background: #102aa3;
  justify-content: flex-end;
}

header .main-header nav {
  display: inline-flex;
  color: white;
  font-weight: bold;
  margin-left: 2rem;
}

header .main-header nav a {
  padding: 0.75em 1.2em;
  border-radius: 3em;
  color: white;
}

@media only screen and (min-width: 768px) {
  header .main-header nav a:hover {
    color: #cccccc !important;
  }
}

header .main-header nav a.active {
  /*background:#00198E;*/
  color: #ffb602;
}

@media only screen and (min-width: 768px) {
  header .main-header nav a.active:hover {
    color: #ffb602 !important;
  }
}

@media only screen and (max-width: 639px) {
  header .main-header nav, header .main-header .social-icons {
    display: none;
  }
}

header .main-header .social-icons li a {
  border: 2px solid white;
}

header .main-header .logo-wrapper {
  flex: 1;
  display: inline-flex;
  margin-right: auto;
  padding: 0 .5rem;
}

header .main-header .logo-wrapper .logo {
  background-image: url("/img/logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 5rem;
  width: 15rem;
}

@media only screen and (max-width: 639px) {
  header .main-header {
    border-bottom: 2px solid #102aa3;
    height: 3.5rem;
  }
  header .main-header .logo-wrapper {
    padding: 0 .5rem;
  }
  header .main-header .logo-wrapper .logo {
    height: 3.5rem;
    width: 14rem;
    padding-top: .5rem;
    background-size: 90%;
    background-position: left center;
  }
}

@media only screen and (max-width: 559px) {
  header .main-header .social-icons {
    display: none;
  }
}

.legal-footer {
  line-height: 1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.legal-footer .content-line {
  display: inline-flex;
  margin-left: .75rem;
  margin-right: .75rem;
}

.legal-footer .content-line:first-of-type {
  margin-left: 0;
}

.legal-footer .content-line:last-of-type {
  margin-right: 0;
}

.legal-footer .content-line a {
  margin-left: .25rem;
}

@media only screen and (max-width: 767px) {
  .legal-footer {
    /*
        flex-direction: column;
        justify-content: center;
        align-items: center;
        */
    display: block;
    text-align: center;
    line-height: 1rem;
  }
  .legal-footer .content-line {
    margin: 0;
    display: inline;
  }
}

main {
  background-color: white;
}

.page-message {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  max-width: 500px;
  background-color: white;
  border: 2px solid #102aa3;
  padding: 1.5rem;
  margin: 0 auto;
}

.page-message h2 {
  color: #102aa3;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-size: 1.35rem;
}

.page-message p {
  color: #102aa3;
}

#error section {
  padding: 5rem 0;
}

#error section .title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: .5rem;
}

#error section .container {
  text-align: center;
}

.hide {
  display: none;
}

#home article h3 {
  font-family: "Eczar", serif;
  font-size: 1.75rem;
}

#home article p {
  line-height: 1.75rem;
  font-size: 1.05rem;
}

#home #home-blocks-wrapper {
  padding: 2rem 0;
}

#home #home-blocks-wrapper .row {
  margin-bottom: 1.5rem;
}

#home #home-blocks-wrapper .row:last-of-type {
  margin-bottom: 0;
}

#home #home-blocks-wrapper .box-wrapper {
  padding-left: .75rem;
  padding-right: .75rem;
  display: inline-block;
  float: left;
  min-height: 16rem;
}

#home #home-blocks-wrapper .box-wrapper:first-of-type {
  padding-left: 0;
}

#home #home-blocks-wrapper .box-wrapper:last-of-type {
  padding-right: 0;
}

#home #home-blocks-wrapper .box-wrapper.episode-preview {
  width: 66.666666%;
}

#home #home-blocks-wrapper .box-wrapper.episode-preview .box {
  background-position: center;
  justify-content: flex-start;
  align-items: flex-end;
}

#home #home-blocks-wrapper .box-wrapper.episode-preview .box .box-content {
  position: relative;
}

#home #home-blocks-wrapper .box-wrapper.episode-preview .box .box-content .btn {
  margin-top: 1rem;
}

#home #home-blocks-wrapper .box-wrapper.episode-preview .box .episode-number {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  align-self: flex-start;
  margin-bottom: .25rem;
}

#home #home-blocks-wrapper .box-wrapper.episode-preview .box .episode-date {
  display: block;
  margin-bottom: .5rem;
  color: white;
  font-size: .8rem;
  font-style: italic;
}

#home #home-blocks-wrapper .box-wrapper.episode-preview .box .episode-title {
  font-family: "Eczar", serif;
  color: white;
  margin-top: .75rem;
  font-size: 1.9rem;
  font-weight: bold;
  margin-bottom: .25rem;
  line-height: 2rem;
}

#home #home-blocks-wrapper .box-wrapper.episode-preview .box .episode-guest {
  color: white;
  text-transform: uppercase;
  font-style: italic;
}

@media only screen and (max-width: 559px) {
  #home #home-blocks-wrapper .box-wrapper.episode-preview .box .episode-guest {
    position: static;
    top: auto;
    right: auto;
    margin-bottom: 1rem;
  }
}

#home #home-blocks-wrapper .box-wrapper.episode-preview .box .episode-intro {
  margin-bottom: 1rem;
  flex-direction: row;
}

#home #home-blocks-wrapper .box-wrapper.episode-preview .box .episode-intro p {
  color: white;
  font-size: 1.05rem;
}

#home #home-blocks-wrapper .box-wrapper.episode-preview .box .episode-intro p .read-more {
  display: inline-flex;
}

@media only screen and (max-width: 767px) {
  #home #home-blocks-wrapper .box-wrapper.episode-preview {
    width: 100%;
    margin-bottom: .75rem;
    padding: 0;
  }
  #home #home-blocks-wrapper .box-wrapper.episode-preview .box {
    min-height: 18rem;
  }
}

#home #home-blocks-wrapper .box-wrapper.quote-box {
  width: 33.333333%;
  text-align: center;
}

#home #home-blocks-wrapper .box-wrapper.quote-box p {
  font-size: 1.5rem;
  color: white;
}

#home #home-blocks-wrapper .box-wrapper.quote-box .box {
  background-image: url("/img/quote-box.jpg");
}

@media only screen and (max-width: 767px) {
  #home #home-blocks-wrapper .box-wrapper.quote-box {
    width: 100%;
    padding: 0;
  }
}

#home #home-blocks-wrapper .box-wrapper.about, #home #home-blocks-wrapper .box-wrapper.episodes, #home #home-blocks-wrapper .box-wrapper.team {
  width: 50%;
}

#home #home-blocks-wrapper .box-wrapper.team .box {
  background-image: url("/img/team-box.jpg");
}

@media only screen and (max-width: 767px) {
  #home #home-blocks-wrapper .box-wrapper.team {
    width: 50%;
  }
}

@media only screen and (max-width: 559px) {
  #home #home-blocks-wrapper .box-wrapper.team {
    width: 100%;
    padding: 0;
  }
}

#home #home-blocks-wrapper .box-wrapper.about .box {
  background-image: url("/img/about-box.jpg");
}

@media only screen and (max-width: 767px) {
  #home #home-blocks-wrapper .box-wrapper.about {
    width: 100%;
    padding: 0;
    margin-bottom: .75rem;
  }
}

#home #home-blocks-wrapper .box-wrapper.episodes .box {
  background-image: url("/img/episodes-box.jpg");
}

@media only screen and (max-width: 767px) {
  #home #home-blocks-wrapper .box-wrapper.episodes {
    width: 50%;
    padding-left: 0;
  }
}

@media only screen and (max-width: 559px) {
  #home #home-blocks-wrapper .box-wrapper.episodes {
    width: 100%;
    padding: 0;
    margin-bottom: .75rem;
  }
}

#home #home-blocks-wrapper .box {
  background-color: #b3b3b3;
  min-height: 16rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#home #home-blocks-wrapper .box .cover {
  background: #102aa3;
  opacity: .35;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: all 0.25s ease;
}

#home #home-blocks-wrapper .box .cover:hover {
  transition: all 0.25s ease;
}

#home #home-blocks-wrapper .box .box-content {
  z-index: 2;
}

#home #home-blocks-wrapper .box h2 {
  color: white;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-size: 1.15rem;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  #home #home-blocks-wrapper .box:hover .cover {
    opacity: .6;
  }
}

@media only screen and (max-width: 1099px) {
  #home #home-blocks-wrapper {
    padding: .75rem 0;
  }
  #home #home-blocks-wrapper .row {
    margin-bottom: .75rem;
  }
  #home #home-blocks-wrapper .box-wrapper {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  #home #home-blocks-wrapper .box-wrapper:first-of-type {
    padding-left: 0;
  }
  #home #home-blocks-wrapper .box-wrapper:last-of-type {
    padding-right: 0;
  }
}

#contact .error-list {
  color: #ff0000;
  padding: 10px;
}

#contact .form-input {
  padding: 10px;
}

#contact .msg {
  padding: .5em;
  margin: 1em 0;
}

#contact .msg p {
  margin: 0;
  text-align: center;
}

#contact .msg.success {
  border: 2px solid #29a913;
  background-color: rgba(41, 169, 19, 0.3);
  color: #636363;
}

#contact .msg.error {
  border: 2px solid #ff0000;
  background-color: rgba(255, 0, 0, 0.3);
  color: #636363;
}

#contact .submit {
  background: blue;
  color: white;
}

#contact .two-col {
  display: flex;
}

@media screen and (max-width: 60em) {
  #contact .two-col {
    flex-direction: column;
  }
}

#contact .contact-info {
  margin-left: 2em;
  min-width: 30%;
}

@media screen and (max-width: 60em) {
  #contact .contact-info {
    margin: 2em 0 0;
  }
}

#contact address {
  line-height: 1.5;
  margin-bottom: 1em;
}

#episodes .episode-box-wrapper {
  padding: .75rem;
  width: 50%;
  float: left;
}

@media only screen and (max-width: 767px) {
  #episodes .episode-box-wrapper {
    float: none;
    width: auto;
  }
}

#episodes .episode-box {
  background-color: gray;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  max-width: 100%;
  align-items: stretch;
  min-height: 20rem;
}

#episodes .episode-box .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #102aa3;
  z-index: 1;
  opacity: .35;
}

#episodes .episode-box .box-content {
  z-index: 2;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
}

#episodes .episode-box .btn {
  margin-top: .5rem;
}

#episodes .episode-box .episode-title {
  font-family: "Eczar", serif;
  color: white;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: .25rem;
  line-height: 2.25rem;
  width: 100%;
}

#episodes .episode-box .episode-intro {
  color: white;
  margin-bottom: 1rem;
}

#episodes .episode-box .episode-intro p {
  color: white;
}

#episodes .episode-box .episode-guest {
  text-transform: uppercase;
  color: white;
  font-style: italic;
  margin-bottom: .75rem;
  font-size: 1.15rem;
}

#episodes .episode-box .episode-number {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  margin-bottom: auto;
}

#episodes .episode-boxes {
  margin: -.75rem;
}

@media only screen and (max-width: 767px) {
  #episodes .episode-boxes {
    grid-template-columns: 100%;
    margin: 0;
  }
  #episodes .episode-boxes .episode-box-wrapper {
    padding: 0;
    padding-bottom: .75rem;
  }
  #episodes .episode-boxes .episode-box-wrapper:last-of-type {
    padding-bottom: 0;
  }
}

.podcast-description {
  margin-bottom: 2.5em;
}

.podcast-description h4 {
  color: #888;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  font-size: 1em;
  margin: 1em 0 0.5em;
}

ul.hosts {
  display: flex;
}

@media screen and (max-width: 470px) {
  ul.hosts {
    flex-direction: column;
  }
  ul.hosts li {
    margin-bottom: 0.5em;
    font-size: 0.9em;
  }
}

ul.hosts li {
  margin-right: 0.5em;
}

ul.hosts li a {
  display: flex;
  align-items: center;
  border-radius: 10em;
  padding: 0.3em;
  padding-right: 1.5em;
}

ul.hosts li a h2 {
  font-size: 1.25em;
  color: inherit;
  line-height: 1;
  margin: 0 0 -0.1em;
}

ul.hosts li a:hover {
  background: #102aa3;
  color: #fff !important;
}

ul.hosts li .photo {
  width: 3em;
  height: 3em;
  border-radius: 5em;
  background: #000;
  background-position: center;
  background-size: cover;
  margin-right: 1em;
  flex: 0 0 3em;
}

#episode aside {
  margin-top: 7rem;
}

@media only screen and (max-width: 767px) {
  #episode aside {
    margin-top: 2rem;
  }
}

#episode .episode-desc {
  margin-top: 2rem;
}

#episode .episode-guest-wrapper {
  margin-top: 4rem;
  background: linear-gradient(180deg, #f2f2f2 0, #f2f2f2 7em, white 0, white);
}

@media only screen and (max-width: 559px) {
  #episode .episode-guest-wrapper {
    margin-top: 2rem;
  }
}

#episode .episode-guest-wrapper h4 {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  color: #102aa3;
  font-weight: bold;
  margin-top: 1rem;
  padding-left: 1rem;
  display: inline-block;
}

#episode .episode-guest-wrapper .guest-info {
  width: auto;
  overflow: hidden;
  padding-left: 2rem;
  padding-top: .5rem;
}

@media only screen and (max-width: 559px) {
  #episode .episode-guest-wrapper .guest-info {
    padding-left: 0;
  }
}

#episode .episode-guest-wrapper .guest-info .guest-name {
  font-family: "Eczar", serif;
  font-size: 2rem;
  margin-bottom: .5rem;
}

#episode .episode-guest-wrapper .guest-info .guest-title {
  font-size: 1.15rem;
  font-style: italic;
  margin-bottom: 1rem;
}

#episode .episode-guest-wrapper .guest-info .guest-bio p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

#episode .episode-guest-wrapper .guest-info .guest-bio p:last-of-type {
  margin-bottom: 0;
}

#episode .episode-guest-wrapper .guest-image-wrapper {
  margin-top: -1rem;
  margin-left: 2rem;
  float: right;
  width: 10rem;
}

@media only screen and (max-width: 559px) {
  #episode .episode-guest-wrapper .guest-image-wrapper {
    margin-left: 0;
  }
}

#episode .episode-guest-wrapper .guest-image-wrapper .guest-image {
  width: 10rem;
  height: 10rem;
  background-color: #cccccc;
  background-size: cover;
  background-position: center;
  border: 1px solid black;
}

@media only screen and (max-width: 639px) {
  #episode .episode-guest-wrapper .guest-image-wrapper {
    float: none !important;
    margin: 0;
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-top: 1rem;
  }
}

#episode .episode-header {
  position: relative;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 559px) {
  #episode .episode-header {
    margin-bottom: 1rem;
  }
}

#episode .episode-date {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: white;
  text-transform: uppercase;
  z-index: 2;
  font-weight: bold;
  font-size: .8rem;
  display: inline-flex;
  align-items: center;
}

#episode .episode-date .date {
  font-size: 1rem;
  margin-left: 1rem;
}

#episode .episode-image-wrapper {
  padding-top: 5rem;
}

#episode .episode-image-wrapper .episode-image {
  min-height: 25rem;
  position: relative;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

#episode .episode-image-wrapper .episode-image .cover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #102aa3;
  opacity: .35;
}

#episode .episode-number-wrapper {
  background: #ffb602;
  color: white;
  padding: .5rem 1rem;
  display: inline-block;
}

#episode .episode-number-wrapper .episode-number {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-size: 1rem;
}

#episode .episode-title {
  padding: 1rem;
  background: white;
  display: inline-block;
  line-height: 2rem;
  margin-right: 3rem;
  vertical-align: baseline;
}

@media only screen and (max-width: 639px) {
  #episode .episode-title {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 559px) {
  #episode .episode-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

#episode .episode-info {
  position: absolute;
  top: 0;
  left: 0;
}

#episode .episode-wrapper {
  width: auto;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  #episode .container .episode-content {
    padding-left: .75rem;
    padding-right: .75rem;
  }
  #episode .container .episode-header {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #episode .container .episode-image-wrapper .episode-image {
    height: 20rem;
  }
}

#contact .error-list {
  color: #ff0000;
  padding: 10px;
}

#contact .form-input {
  padding: 10px;
}

#contact .msg {
  padding: .5em;
  margin: 1em 0;
}

#contact .msg p {
  margin: 0;
  text-align: center;
}

#contact .msg.success {
  border: 2px solid #29a913;
  background-color: rgba(41, 169, 19, 0.3);
  color: #636363;
}

#contact .msg.error {
  border: 2px solid #ff0000;
  background-color: rgba(255, 0, 0, 0.3);
  color: #636363;
}

#contact .submit {
  background: blue;
  color: white;
}

#contact .two-col {
  display: flex;
}

@media screen and (max-width: 60em) {
  #contact .two-col {
    flex-direction: column;
  }
}

#contact .contact-info {
  margin-left: 2em;
  min-width: 30%;
}

@media screen and (max-width: 60em) {
  #contact .contact-info {
    margin: 2em 0 0;
  }
}

#contact address {
  line-height: 1.5;
  margin-bottom: 1em;
}

#team #team-tile {
  padding-bottom: 4rem;
}

#team #guests-tile {
  padding: 2rem 0;
}

#team .hosts-wrapper {
  display: flex;
  width: 100%;
}

#team .hosts {
  display: inline-flex;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  #team .hosts {
    flex-direction: column;
  }
}

#team .hosts .host {
  width: 50%;
  padding-left: .75rem;
  padding-right: .75rem;
}

@media only screen and (max-width: 767px) {
  #team .hosts .host {
    width: 100%;
    display: flex;
    padding: 0;
    margin-bottom: 3rem;
  }
  #team .hosts .host:last-of-type {
    margin-bottom: 0;
  }
}

#team .hosts .host:first-of-type {
  padding-left: 0;
}

#team .hosts .host:last-of-type {
  padding-right: 0;
}

#team .hosts .host .block-content {
  background-color: #e6e6e6;
  background-size: cover;
  width: 100%;
  background-position: center;
  min-height: 20rem;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
}

#team .hosts .host .block-content h2 {
  position: absolute;
  bottom: -1rem;
  left: 1rem;
  background: #102aa3;
  color: white;
  padding: .5rem;
  padding-right: 1.5rem;
  padding-top: .75rem;
  padding-left: .75rem;
}

#team .hosts .host.mark-young .block-content {
  background-image: url("/img/hosts/mark-young.jpg");
}

#team .hosts .host.dr-gladden .block-content {
  background-image: url("/img/hosts/dr-gladden.jpg");
}

#team .hosts .host.robb-taylor .block-content {
  background-image: url("/img/hosts/robb-taylor.jpg");
}

#team-show .bio-wrapper {
  margin-bottom: 2rem;
}

#team-show .bio-wrapper:last-of-type {
  margin-bottom: 0;
}

#team-show .bio-wrapper p {
  font-size: 1.15rem;
  line-height: 1.85rem;
  margin-bottom: 1.5rem;
}

#team-show .bio-wrapper p:last-of-type {
  margin-bottom: 0;
}

#team-show .bio-wrapper h2 {
  margin-bottom: .5rem;
}

#team-show .images-wrapper {
  margin-bottom: 2rem;
}

#team-show .images-wrapper .image-wrapper {
  padding-left: .75rem;
  padding-right: .75rem;
  width: 33.3333%;
  float: left;
}

#team-show .images-wrapper .image-wrapper:first-of-type {
  padding-left: 0;
}

#team-show .images-wrapper .image-wrapper:last-of-type {
  padding-right: 0;
}

#team-show .images-wrapper .image {
  height: 17rem;
  background-color: #cccccc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 1.5rem;
}

#team-show .images-wrapper .container {
  flex-direction: row;
}

@media only screen and (max-width: 767px) {
  #team-show .images-wrapper .image-wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  #team-show .images-wrapper .image {
    height: 20rem;
  }
}

#team-show .image-banner {
  height: 25rem;
  background-color: #cccccc;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#team-show .host-bio {
  background: white;
  padding-left: 1rem;
  padding-right: 1rem;
}

#team-show .host-bio .social-icons {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #cccccc;
  flex-direction: column;
}

#team-show .host-bio .social-icons .fa {
  color: #102aa3;
}

#team-show .host-bio .social-icons .social-title {
  display: block;
  margin-bottom: 1rem;
  font-size: 1rem;
}

#team-show .host-bio .social-icons ul {
  display: flex;
}

#articles .main-content {
  background: white;
}

#articles .articles-wrapper .post-wrapper {
  display: flex;
}

#articles .articles-wrapper .post-wrapper .image-wrapper, #articles .articles-wrapper .post-wrapper .info-wrapper {
  display: inline-flex;
}

#articles .articles-wrapper .post-wrapper .image-wrapper {
  height: 7rem;
  width: 7rem;
  border: 1px solid gray;
  background-color: #e6e6e6;
  margin-right: 1rem;
  background-size: cover;
}

#articles .articles-wrapper .post-wrapper .info-wrapper {
  flex-direction: column;
}

#articles .articles-wrapper .title {
  font-size: 1.75rem;
  max-width: 960px;
}

#article .post-wrapper .image-wrapper {
  background-size: cover;
  background-position: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
  width: 100%;
  height: 25rem;
}

#article .post-wrapper .post-header .article-info {
  float: left;
  clear: left;
  font-size: .95em;
  font-style: italic;
}

#article .post-wrapper .post-header .img-credit {
  font-size: .95em;
  font-style: italic;
  float: right;
  clear: right;
}

#article .post-wrapper .post-content {
  clear: both;
  padding-top: 10px;
}

#article .post-wrapper .body-wrapper img {
  float: left;
  margin-right: 10px;
}
