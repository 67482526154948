#articles{
    .main-content{
        background: white;
    }
    .articles-wrapper{
        // article{
        .post-wrapper{
            display: flex;
            .image-wrapper, .info-wrapper{
                display: inline-flex;
            }
            .image-wrapper{
                height: 7rem;
                width: 7rem;
                border: 1px solid $grey;
                background-color: $light-1;
                margin-right: 1rem;
                background-size:cover;
            }
            .info-wrapper{
                // display: flex;
                flex-direction: column;
                // justify-content: center;
            }
        }
        .title{
            font-size: 1.75rem;
            max-width: 960px;
        }
        // }
    }
}
#article{
    .post-wrapper{
        .image-wrapper{
            background-size: cover;
            background-position: center;
            margin-bottom: 2rem;
            margin-top: 1rem;
            width: 100%;
            height: 25rem;
            @extend .card-shadow;
        }
        .post-header {
            .article-info {
                float: left;
                clear: left;
                font-size: .95em;
                font-style: italic;
            }
            .img-credit {
                font-size: .95em;
                font-style: italic;
                float: right;
                clear: right;
            }
        }
        .post-content{
            clear: both;
            padding-top: 10px;
        }
        .body-wrapper{
            img {
                float: left;
                margin-right: 10px;
            }
        }
    }
}
